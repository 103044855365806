.join-beta {
  background: url("images/join_beta/banner.png") no-repeat center center;

  @screen lg {
    height: 400px;
  }

  @screen xl {
    height: 550px;
  }
}