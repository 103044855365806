.ems-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0;

  > thead > tr > th, > tbody > tr > td {
    font-size: 14px;
    color: #293538;
    border-color: #BAC2C6;
    padding: 8px
  }

  thead {
    &.sticky-custom {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    tr {
      th {
        background: #F6F9FC;
        text-align: left;
        vertical-align: middle;
        font-weight: 600;
        font-size: 13px;
        border-style: solid;
        line-height: 15.73px;

        color: #626F77;
      }

      &:first-child {
        border-top-width: 1px;
        //border-bottom-width: 1px;

        th {
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }

  > tbody {
    > tr {
      border-color: #BAC2C6;

      > td {
        border-top: solid 1px #BAC2C6;
        vertical-align: top;
        background: #ffffff;
      }

      &:first-child {
        > td {
          border-top-width: 0;
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        background: #ffffff;
        border-top: solid 1px #BAC2C6;

        &:first-child {
          border-bottom-left-radius: 5px;
        }

        &.activate {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

}

.ems-table-with-borders {
  thead {
    tr {
      th {
        border-top: solid 1px #BAC2C6;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:first-child {
          border-left: solid 1px #BAC2C6;

        }

        &:last-child {
          border-right: solid 1px #BAC2C6;
        }
      }

      &:first-child {
        th {
          &:first-child {
            border-bottom-left-radius: 0;
          }
        }

        th {
          &:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }

      &:last-child {
        th {
          border-bottom: solid 1px #BAC2C6;
        }
      }
    }
  }

  > tbody {
    > tr {
      &:last-child {
        > td {
          border-bottom-width: 1px;
        }
      }

      > td {
        &:first-child {
          border-left-width: 1px;
        }

        &:last-child {
          border-right-width: 1px;
        }
      }
    }
  }
}