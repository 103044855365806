.part-tabs {
  .tab-tools {
    margin-top: 54px;
    margin-bottom: 146px;
    color: #FFFFFF;

    .tab-list {
      padding: 0;
    }

    .tab-item {
      //background: #B9C6D6;
      height: 56px;
      z-index: 2;
      &.tab-item-active{
        .bom-title{
          h4{
            text-decoration: underline;
          }
          
        }
      }
    }

    .tab-cursor {
      //background: #E2E9F1;
    }

    .tab-panels {
      position: relative;
      transform-style: preserve-3d;

      &::before {
        content: "";
        position: absolute;
        inset: -5px;
        transform: translate3d(10px, 8px, -1px);
        background: linear-gradient(90deg, #1A222D, #0B1015);
        //z-index: -1;
        filter: blur(10px);
      }
      
      >div {
        padding: 24px 17px;

        @screen lg {
          padding: 38px 51px 19px 52.2px;
        }
      }
    }

    .tab-content {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}