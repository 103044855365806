#privacy-policy
  width: fit-content
  margin-top: 48px
  margin-bottom: 48px
  padding-top: 24px
  padding-bottom: 24px

  .date-subtitle
    font-size: 16px
    font-weight: 500
    line-height: 21.86px
    text-align: left
    color: #008AA1

  .title
    font-weight: 700
    font-size: 34px
    line-height: 46.44px

  .content
    margin-left: 21px
    max-width: 600px
    font-size: 16px
    font-weight: 500
    line-height: 21.86px
    letter-spacing: -0.01em
    text-align: left

    .subtitle
      font-weight: 700
