#terms-conditions
  width: fit-content
  margin-top: 48px
  margin-bottom: 48px
  padding-top: 24px
  padding-bottom: 24px

  .title
    font-weight: 700
    font-size: 34px
    line-height: 46.44px

  .content
    padding-left: 21px
    padding-right: 21px
    font-size: 16px
    font-weight: 400
    line-height: 21.86px
    letter-spacing: -0.01em
    text-align: left

    .subtitle
      font-weight: 700

    .panel
      .panel-title
        h4
          font-size: 18px
          font-weight: bold

      .panel-content
        font-weight: 400