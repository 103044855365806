@tailwind base;
@tailwind components;
@tailwind utilities;

@import "shared/validator";
@import "shared/ems_table";
//@import "./variables";
//@import "bootstrap/scss/bootstrap";

//@import "./shared/account";
//@import "./shared/buttons";

//@import "./shared/pagination";

html {
  //font-size: 14px;
  font-family: "Manrope";
}

// prevent NextUI Skeleton place a invisible(opacity: 0) element on nearest component
// causing a not clickable element
.data-\[loaded\=true\]\:before\:opacity-0[data-loaded="true"]::before {
  display: none;
}

@layer base {

  $heading_color: #283546;

  .h1 {
    font-size: 33px;
    font-weight: 800;
    color: $heading_color;

    @screen lg {
      font-size: 58px;
    }

    @screen 2xl {
      font-size: 60px;
    }
  }

  .h2 {
    font-size: 30px;
    line-height: 34px;
    font-weight: 800;
    color: $heading_color;

    @screen lg {
      font-size: 44px;
      line-height: 48px;
    }

    @screen 2xl {
      font-size: 46px;
      line-height: 49px;
    }
  }

  .h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: $heading_color;

    @screen lg {
      font-size: 36px;
      line-height: 42px;
    }

    @screen 2xl {
      font-size: 38px;
      line-height: 44px;
    }
  }


  .h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: $heading_color;

    @screen lg {
      font-size: 28px;
      line-height: 34px;
    }

    @screen 2xl {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .h5 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    color: $heading_color;

    @screen lg {
      font-size: 23px;
      line-height: 28px;
    }

    @screen 2xl {
      font-size: 25px;
      line-height: 29px;
    }
  }

  .h6 {
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    color: $heading_color;

    @screen lg {
      font-size: 21px;
      line-height: 26px;
    }

    @screen 2xl {
      font-size: 23px;
      line-height: 28px;
    }
  }

  .h7 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $heading_color;

    @screen lg {
      font-size: 18px;
      line-height: 22px;
    }

    @screen 2xl {
      font-size: 21px;
      line-height: 26px;
    }
  }

  .p-xsmall {
    font-size: 14px;
    font-weight: 500;

    @screen lg {
      font-size: 13px;
    }

    @screen 2xl {
      font-size: 15px;
    }
  }

  .p-small {
    font-size: 15px;
    font-weight: 500;

    @screen lg {
      font-size: 16px;
    }

    @screen 2xl {
      font-size: 18px;
    }
  }

  .p-medium {
    font-size: 16px;
    font-weight: 500;

    @screen lg {
      font-size: 16px;
    }

    @screen 2xl {
      font-size: 18px;
    }
  }

  .p-large {
    font-size: 18px;
    font-weight: 500;

    @screen lg {
      font-size: 18px;
    }

    @screen 2xl {
      font-size: 20px;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.preferences-scrollbar::-webkit-scrollbar {
  width: 10px;
  padding-left: 5px;
}

/* Track */
.preferences-scrollbar::-webkit-scrollbar-track {
  background: #E2E9F1;
  border-radius: 20px;
}

/* Handle */
.preferences-scrollbar::-webkit-scrollbar-thumb {
  background: #283546;
  border-radius: 20px;

}

/* Handle on hover */
.preferences-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2e3d51;
}