#contact-us
  background: #fff url("images/BOM_Admin_contact_us_bg.png") no-repeat right bottom
  //background-image: asset-url('BOM_Admin_contact_us_bg.png')
  width: 100%
  
  .container
    width: fit-content
    padding-top: 72px
    padding-bottom: 72px

    .card-block
      max-width: 525px

      .contact-us-title
        font-size: 21px
        font-weight: 700
        line-height: 20px
        text-align: left
        color: #008AA1

      .title
        font-size: 24px
        font-weight: 700
        line-height: 30px
        text-align: left

      .subtitle
        font-size: 18px
        font-weight: 400
        line-height: 20px
        text-align: left
      

      


