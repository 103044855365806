footer.app-footer
  background: #1A222D
  color: #FFFFFF
  padding-top: 20px
  font-size: 14px

  .btn-feedback
    margin-left: 14px

  .info-links-wrap
    margin-top: 17px

  .info-links
    .info-link
      a
        color: #ffffff
        text-decoration: auto
        &:hover
          text-decoration: underline

    .info-link+.info-link
      border-left: solid 1px #fff
      padding-left: 8px


  .copyrights
    color: #A2A9AF
    padding: 6px 0
    border-top: solid 1px #2F3A50
    font-size: 12px
