.engineer-tools {
  color: #ffffff;
  margin-top: 146px;

  .title {
    font-weight: bold;
    margin-bottom: 20px;
    display: inline-block;
  }

  .tool-wrap {
    border-radius: 10px;

    @screen lg {
      min-height: 330px;
      width: 330px;
    }




    &.smart-dfm {
      background: url("images/home/smart-dfm.png") no-repeat;
      background-size: cover;

      .tool-wrap-gradient {
        background: linear-gradient(0deg, rgba(26, 34, 45, 0) 0%, rgba(35, 55, 82, 0.95) 61.97%);
      }

      .tool-ico {
        width: 34px;
      }
    }

    &.pcb-navigator {
      background: url("images/home/pcb-navigator.png") no-repeat;
      background-size: cover;

      .tool-wrap-gradient {
        background: linear-gradient(0deg, rgba(26, 34, 45, 0) 0%, #003E51 61.97%);
      }

      .tool-ico {
        width: 44px;
      }
    }

    &.pick-place {
      background: url("images/home/pick-and-place-file-builder.png") no-repeat;
      background-size: cover;

      .tool-wrap-gradient {
        background: linear-gradient(0deg, rgba(26, 34, 45, 0) 0%, #004386 61.97%);
      }

      .tool-ico {
        width: 54px;
      }
    }

    .tool-wrap-gradient {
      border-radius: 10px;
    }
  }

  .tool-ico {
    //width: 61px;
    //height: 61px;
    //border-radius: 50%;
    //background: #D9D9D9;
  }

  .tool-name {
    font-size: 23px;
    font-weight: bold;
    margin-top: 20px;
  }

  .tool-description {
    font-size: 21px;
    font-weight: 500;
  }
}