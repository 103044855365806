#welcome {
  @import "home/main-content";
  @import "home/main_content/part_section";
  @import "home/main_content/engineering_tools";
  @import "home/why_use";
  @import "home/easy_tools";
  @import "home/how_bom_search_works";
  @import "home/engineer_said";
  @import "home/join_beta";

  .distributors {
    .title-section {
      text-align: center;
      font-size: 36px;
    }
  }
}