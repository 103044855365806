#bom-tool {
  background: #fff;

  .main-content {
    .bom-tool-main-section {
      background: #f7f9fc;
      font-family: Inter, sans-serif;

      .bom-file-block {
        .drag-drop-bom {
          border: 2px #c3c3c3 dashed;
          border-radius: 12px;
          padding: 24px 24px;

          a {
            color: #3671b0;
            text-decoration: underline;
          }

          &.dragging {
            background-color: #e7e7e7;
          }
        }
      }
    }
  }
}
        