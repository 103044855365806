//sing up and sig in
#sessions
  height: 100%

  .container

    .form-container
      background: #ffffff
      border: #3b485b 1px solid
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
      padding: 40px

      h2
        font-weight: bold
        font-size: 24px
        margin-bottom: 40px


      a
        color: #4d4d4d
        font-size: 12px
        text-decoration: underline

    #log-in-form-content
      background: #fff
      height: 100%
