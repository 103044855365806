.engineer-said {
  .testimonial {
    .content {
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(246,249,252,1) 100%);

      .text {
        font-size: 18px;
      }
    }
  }
}