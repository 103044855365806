.main-content {
  //background: rgb(54,65,84);
  //background: linear-gradient(180deg, rgba(54,65,84,1) 0%, rgba(27,33,45,1) 100%);

  background: url("images/home/electronic-part-search-engine.png") no-repeat;
  background-color: #1A222D;
  background-size: contain;
  padding-top: 154px; // 62px is the padding after the header, 92px is the height of the header
  padding-bottom: 91px;

  .section-title {
    color: #ffffff;
    font-weight: 800;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -2%;
    text-align: center;
  }
}