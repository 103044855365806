.how-bom-search-works {
  color: #FFFFFF;

  .steps-wrap {
    background: #283546;
    //padding: 137px 54px;

    .steps {
      li + li {
        border-top: solid 1px #1A222D;
      }
      li {
        font-size: 20px;

        @screen lg {
          font-size: 28px;
        }

        .count {
          font-size: 21px;
        }
      }
    }
  }
}